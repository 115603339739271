import { inject, Injectable } from "@angular/core";
import { UtilsService } from "./utils.service";
import { map } from "rxjs";
import { AccessControl } from "../constants/access-control";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  private utilsService: UtilsService = inject(UtilsService);
  private accessContorl = new AccessControl();
  private accessTokenKey = "iOnePredict";
  private refreshTokenKey = "iOnePredictRefresh";
  checkAuthentication(): any {
    let token = this.getAccessToken();
    return token ? true : false;
  }
  getAccessToken() {
    const encodedToken = localStorage.getItem(this.accessTokenKey);
    return encodedToken ? this.decodingToken(encodedToken) : null;
  }
  getStoredAccessToken() {
    return localStorage.getItem(this.accessTokenKey);
  }
  getStoredRefreshToken() {
    return localStorage.getItem(this.refreshTokenKey);
  }
  private decodingToken(token: string): string {
    if (token) {
      const decodeLevel2 = atob(token);
      const decodeLevel1 = atob(decodeLevel2);
      return decodeLevel1;
    }
    return "";
  }
  private encodingToken(token: string): string {
    if (token) {
      const encodeLevel1 = btoa(token);
      const encodeLevel2 = btoa(encodeLevel1);
      return encodeLevel2;
    }
    return "";
  }
  private saveToken(token: any): void {
    if (token) {
      const encodedToken = this.encodingToken(token.accessToken);
      localStorage.setItem(this.accessTokenKey, encodedToken);
      if (token.refreshToken) {
        localStorage.setItem(this.refreshTokenKey, token.refreshToken);
      }
    }
  }
  private fetchToken(
    token: string,
    type: string,
    isRefreshToken: boolean = false
  ) {
    return this.utilsService
      .httpPostWithOptions(
        this.accessContorl.token(),
        { token, type },
        { headers: { skip: "true" } }
      )
      .pipe(
        map((token) => {
          if (isRefreshToken && token === "token_expired") {
            localStorage.clear();
            return "";
          }
          this.saveToken(token);
          return token;
        })
      );
  }
  getToken(code: string, isRefreshToken: boolean = false) {
    console.log(code);
    this.fetchToken(
      code,
      isRefreshToken ? "REFRESHTOKEN" : "CODE",
      isRefreshToken
    ).subscribe({
      next: (data) => {
        console.log("got token");
        let url = window.location.origin;
        if (window.location.pathname) {
          url += window.location.pathname;
        }
        window.location.replace(url);
      },
      error: (err) => {
        console.log(err);
        this.login();
      },
    });
  }
  login() {
    return this.utilsService
      .httpPostWithOptions(
        this.accessContorl.token(),
        {},
        { headers: { skip: "true" } }
      )
      .pipe(
        map((response) => {
          return response;
        })
      )
      .subscribe({
        next: (data) => {
          window.location.href = data.toString();
        },
        error: (err) => {
          console.log(err);
        },
      });
  }
  logout() {
    this.utilsService
      .httpGet(this.accessContorl.logout())
      .pipe()
      .subscribe((data) => {
        const URI = data;
        const allowlist = "ionecloud.lntebg.com";
        const allowProd = "smartcommcloud.lntebg.com";
        localStorage.clear();
        if (URI?.indexOf(allowlist) > -1 || URI?.indexOf(allowProd) > -1) {
          window.location.href = URI.toString();
        }
      });
  }
  setTokenOnLocal(token: string) {
    const encodedToken = this.encodingToken(token);
    localStorage.setItem(this.accessTokenKey, encodedToken);
  }
  getAccessKey() {
    return this.accessTokenKey;
  }
  getAccessRefreshKey() {
    return this.refreshTokenKey;
  }
}
