<div class="register-container">
    <img
        class="app-logo-img"
        src="../../../../assets/logo/register-logo-apps.png"
    />
    <div class="register-section">
        <div class="logo-container">
            <div class="logo">
                <img
                    src="../../../../assets/logo/LK_Digital_Logo.png"
                    alt="LK Digital"
                    width="130"
                />
            </div>
        </div>
        <div class="register-info">
            <div class="sign-up-info">
                <span class="sign-up me-2">Sign Up</span
                ><span class="me-2"
                    >Already have an account ?
                    <a class="form-link-text" (click)="goToLogin()"
                        >Sign in</a
                    ></span
                >
            </div>
            <form [formGroup]="registerForm" class="register-form">
                <div class="form-field block-field">
                    <mat-label class="required">First Name</mat-label>
                    <mat-form-field>
                        <input
                            matInput
                            type="text"
                            placeholder="First name"
                            formControlName="firstName"
                            required
                        />
                        <mat-error
                            *ngIf="
                                registerForm
                                    .get('firstName')
                                    .hasError('required')
                            "
                        >
                            First name is reuired
                        </mat-error>
                        <mat-error
                            *ngIf="
                                registerForm
                                    .get('firstName')
                                    .hasError('pattern')
                            "
                        >
                            Only Alphabets are allowed
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="form-field block-field">
                    <mat-label class="required">Last Name</mat-label>
                    <mat-form-field>
                        <input
                            matInput
                            type="text"
                            placeholder="Last name"
                            formControlName="lastName"
                            required
                        />
                        <mat-error
                            *ngIf="
                                registerForm
                                    .get('lastName')
                                    .hasError('required')
                            "
                        >
                            Last name is reuired
                        </mat-error>
                        <mat-error
                            *ngIf="
                                registerForm.get('lastName').hasError('pattern')
                            "
                        >
                            Only Alphabets are allowed
                        </mat-error>
                        <mat-error
                            *ngIf="registerForm['errors']?.['displayName']"
                        >
                            FirstName and LastName shouldn't match
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="form-field block-field">
                    <mat-label class="required">Mobile Number</mat-label>
                    <mat-form-field>
                        <input
                            matInput
                            type="text"
                            placeholder="Mobile Number"
                            formControlName="mobileNumber"
                            maxlength="10"
                            required
                        />
                        <span
                            matSuffix
                            *ngIf="
                                registerForm.get('mobileNumber').errors ===
                                    null &&
                                !verifiedMobileOTP &&
                                !otpInProgress
                            "
                            type="button"
                            (click)="generateMobileOTP()"
                        >
                            Get OTP</span
                        >
                        <span
                            matSuffix
                            *ngIf="
                                registerForm.get('mobileNumber').errors ===
                                    null &&
                                !verifiedMobileOTP &&
                                otpInProgress
                            "
                            type="button"
                            disabled
                        >
                            {{ display }}
                        </span>
                        <span
                            matSuffix
                            *ngIf="
                                registerForm.get('mobileNumberOTP').errors ===
                                    null && verifiedMobileOTP
                            "
                            type="button"
                            id="mobileVerfiyBtn"
                        >
                            Verified <i class="fas fa-check ps-1"></i>
                        </span>
                        <mat-error
                            *ngIf="
                                registerForm
                                    .get('mobileNumber')
                                    .hasError('required')
                            "
                        >
                            Mobile Number is required</mat-error
                        >
                        <mat-error
                            *ngIf="
                                registerForm
                                    .get('mobileNumber')
                                    .hasError('pattern')
                            "
                        >
                            enter an valid mobile number</mat-error
                        >
                        <mat-error *ngIf="errorGenerateOTP">{{
                            errorGenerateOTP
                        }}</mat-error>
                    </mat-form-field>
                </div>
                <div class="form-field block-field" *ngIf="showMobileOTP">
                    <mat-label class="required">Mobile Number - OTP</mat-label>
                    <mat-form-field>
                        <input
                            matInput
                            type="text"
                            placeholder="Enter OTP"
                            id="mobilenumberOTP"
                            maxlength="6"
                            name="mobileNumberOTP"
                            formControlName="mobileNumberOTP"
                            required
                        />
                        <span
                            matSuffix
                            *ngIf="
                                registerForm.get('mobileNumberOTP').errors ===
                                null
                            "
                            class="btn bg-white otp-button"
                            type="button"
                            id="mobileVerfiyBtn"
                            (click)="verfiyMobileOTP()"
                        >
                            Verify
                        </span>
                        <mat-error
                            *ngIf="
                                registerForm
                                    .get('mobileNumberOTP')
                                    .hasError('required')
                            "
                        >
                            Mobile Number - OTP is required</mat-error
                        >
                        <mat-error
                            *ngIf="
                                registerForm
                                    .get('mobileNumberOTP')
                                    .hasError('pattern')
                            "
                        >
                            enter an valid OTP number</mat-error
                        >
                        <mat-error *ngIf="errorVerifyOTP">{{
                            errorVerifyOTP
                        }}</mat-error>
                    </mat-form-field>
                </div>
                <div class="form-field block-field">
                    <mat-label class="required">Email ID</mat-label>
                    <mat-form-field>
                        <input
                            matInput
                            type="email"
                            placeholder="Email ID*"
                            name="emailId"
                            id="emailid"
                            (blur)="checkMailtoUse()"
                            formControlName="emailId"
                            required
                        />
                        <span
                            matSuffix
                            type="button"
                            disabled
                            class="btn bg-white otp-button"
                            *ngIf="
                                emailLoader
                                    ? emailLoader
                                    : verifyEmail
                                    ? verifyEmail
                                    : errorEmail
                            "
                            [ngClass]="{
                                'is-invalid':
                                    (registerForm.get('emailId').touched &&
                                        registerForm.get('emailId').errors) ||
                                    errorEmail
                            }"
                        >
                            <span
                                *ngIf="emailLoader"
                                class="spinner-grow spinner-grow-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            <i
                                *ngIf="emailLoader ? false : verifyEmail"
                                class="fas fa-check ps-1"
                            ></i>
                            <i
                                *ngIf="emailLoader ? false : !verifyEmail"
                                class="fas fa-times ps-1 error-text"
                            ></i>
                        </span>
                        <mat-error
                            *ngIf="
                                registerForm.get('emailId').hasError('required')
                            "
                        >
                            Email ID is required
                        </mat-error>
                        <mat-error
                            *ngIf="
                                registerForm.get('emailId').hasError('email')
                            "
                        >
                            enter an valid email validation
                        </mat-error>
                        <mat-error
                            *ngIf="
                                registerForm.get('emailId').errors === null &&
                                errorEmail
                            "
                            >{{ errorEmail }}</mat-error
                        >
                    </mat-form-field>
                </div>
                <div class="form-field block-field">
                    <mat-label required>Country</mat-label>
                    <mat-form-field>
                        <mat-select
                            placeholder="Select country"
                            formControlName="country"
                            required
                        >
                            @for (country of countries; track country){
                            <mat-option [value]="country.code">{{
                                country.name
                            }}</mat-option>
                            }
                            <mat-error
                                *ngIf="
                                    registerForm
                                        .get('country')
                                        .hasError('required')
                                "
                            >
                                Country is required></mat-error
                            >
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="form-field">
                    <mat-checkbox formControlName="hasAgreementSigned"
                        >I have read and agree to SmartComm
                        <a
                            class="form-link-text"
                            (click)="openTermsOfUse(privacyPolicy)"
                            role="button"
                            >Privacy Policy</a
                        >,
                        <a
                            class="form-link-text"
                            (click)="openTermsOfUse(privacyNotice)"
                            role="button"
                            >Privacy Notice</a
                        >
                        and
                        <a
                            class="form-link-text"
                            (click)="openTermsOfUse(termsURL)"
                            role="button"
                            >Terms of Use</a
                        ></mat-checkbox
                    >
                </div>
                <div class="form-field">
                    <mat-checkbox formControlName="hasNewsLetterSubscribed"
                        >I consent to receive Notifications on Product,
                        Solutions & Service from SmartComm in accordance with
                        SmartComm's
                        <a
                            class="form-link-text"
                            (click)="openTermsOfUse(privacyPolicy)"
                            role="button"
                            >Privacy Policy</a
                        ></mat-checkbox
                    >
                </div>
                <div class="mt-4 text-center">
                    <button
                        mat-flat-button
                        color="primary"
                        type="submit"
                        [disabled]="
                            registerForm.invalid ||
                            errorGenerateOTP !== '' ||
                            errorVerifyOTP !== '' ||
                            !verifyEmail ||
                            submitted ||
                            !verifiedMobileOTP ||
                            showInlineLoader
                        "
                        (click)="sendFormData()"
                    >
                        @if(!showInlineLoader){
                        <span>SignUp</span>
                        }@else {
                        <span>Loading...</span>
                        }
                    </button>
                </div>
            </form>
        </div>
        <p class="copyright">
            Copyright &#64; 2024.SMARTCOMM.All rights reserved
        </p>
    </div>
</div>
